import { createMuiTheme } from '@material-ui/core/styles';

export const primaryColor = '#E9005F';
export const secondaryColor = '#15E8BF';
export const tertiaryColor = '#ffa800';
export const darkColor = '#37344C';
export const blackColor = '#222222';
export const grayColor = '#D3D3DD';
export const darkGrayColor = '#64646D';
export const whiteTint = 'rgba(255,255,255,0.08)';
export const whiteTintStrong = 'rgba(255,255,255,0.5)';
export const primaryTint = 'rgba(233,0,95, 0.1)';
export const secondaryTint = 'rgba(21,232,191, 0.1)';

export const radius = 3;

export const orangePinkGradient =
  'linear-gradient(135deg, #ffa800 0%, #e9005f 100%)';

export const greenGradient =
  'linear-gradient(135deg, #2FF5E0 0%, #15E8BE 100%)';

export const blackGradient = 'linear-gradient(150deg, #666482 0%, #36344B 75%)';

export const transformTransition =
  'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms';

export const theme = createMuiTheme({
  palette: {
    common: {
      black: blackColor,
      white: '#ffffff',
      lightBlack: darkColor,
    },
    primary: {
      main: primaryColor,
      contrastText: '#ffffff',
      dark: darkColor,
    },
    secondary: {
      main: secondaryColor,
      light: '#C4F9EF',
      contrastText: darkColor,
    },
    tertiary: {
      main: tertiaryColor,
      contrastText: darkColor,
    },
    error: {
      main: primaryColor,
      contrastText: '#ffffff',
    },
    text: {
      primary: darkColor,
      secondary: darkGrayColor,
      disabled: grayColor,
      hint: grayColor,
    },
    divider: grayColor,
    background: {
      paper: '#ffffff',
      default: '#F7F7F9',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Barlow", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    button: {
      fontSize: '1rem',
      color: '#37344C',
    },
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: '1.2',
      marginBottom: 20,

      '@media (min-width: 600px)': {
        fontSize: '3.75rem',
      },
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      marginBottom: 20,
      marginTop: 40,

      '&:first-child': {
        marginTop: 0,
      },

      '@media (min-width: 600px)': {
        fontSize: '3rem',
      },
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: 10,
      marginTop: 40,

      '&:first-child': {
        marginTop: 0,
      },
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 700,

      '@media (min-width: 600px)': {
        fontSize: '1.25rem',
      },
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1.125rem',
      lineHeight: '1.4',
      marginTop: 20,
      '@media (min-width: 600px)': {
        fontSize: '1.25rem',
        lineHeight: '1.5',
      },
    },
    subtitle2: {
      color: darkGrayColor,
      fontSize: '1rem',
      lineHeight: '1.4',
      textTransform: 'uppercase',
    },
  },
  spacing: {
    unit: 10,
  },
  overrides: {
    // Button
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '1.125rem',
        fontWeight: 700,
        padding: '10px 20px',

        '&:hover': {
          textDecoration: 'none',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
          '&$disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
      text: {
        padding: '10px 0px',
        borderRadius: 0,
        '&:after': {
          borderBottom: `2px solid`,
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '""',
          position: 'absolute',
          right: 0,
          transform: 'scaleX(0)',
          transition: transformTransition,
        },
        '&:hover,&:focus': {
          backgroundColor: 'transparent',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
          '&:after': {
            transform: 'scaleX(1)',
          },
        },
      },
      /* Styles applied to the root element if `variant="text"` and `color="primary"`. */
      textPrimary: {
        '&:after': {
          borderColor: primaryColor,
        },
        '&:hover': {
          backgroundColor: 'none',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      /* Styles applied to the root element if `variant="text"` and `color="secondary"`. */
      textSecondary: {
        '&:after': {
          borderColor: secondaryColor,
        },
        // color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: 'none',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      /* Styles applied to the root element for backwards compatibility with legacy variant naming. */
      flat: {},
      /* Styles applied to the root element for backwards compatibility with legacy variant naming. */
      flatPrimary: {},
      /* Styles applied to the root element for backwards compatibility with legacy variant naming. */
      flatSecondary: {},
      /* Styles applied to the root element if `variant="outlined"`. */
      outlined: {
        borderWidth: '2px',
        '&:after': {
          borderBottom: '2px solid',
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '""',
          position: 'absolute',
          right: 0,
          transform: 'scaleX(0)',
          transition: transformTransition,
        },
        '&:hover,&:focus': {
          '&:after': {
            transform: 'scaleX(1)',
          },
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
      outlinedPrimary: {
        '&:after': {
          borderColor: primaryColor,
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
      outlinedSecondary: {
        color: darkColor,
        '&:after': {
          borderColor: secondaryColor,
        },
      },
      /* Styles applied to the root element if `variant="[contained | fab]"`. */
      contained: {
        backgroundColor: darkColor,
        color: '#fff',
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 600,
        '&:after': {
          borderBottom: `2px solid`,
          borderBottomColor: secondaryColor,
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '""',
          position: 'absolute',
          right: 0,
          transform: 'scaleX(0)',
          transition: transformTransition,
          borderRadius: '0px 0px 3px 3px',
        },
        '&:hover,&:focus': {
          backgroundColor: secondaryColor,
          color: blackColor,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: secondaryColor,
            color: blackColor,
          },
          '&:after': {
            transform: 'scaleX(1)',
          },
        },
      },
      /* Styles applied to the root element if `variant="[contained | fab]"` and `color="primary"`. */
      containedPrimary: {
        '&:hover,&:focus': {
          backgroundColor: '#ef4087',
        },
      },
      /* Styles applied to the root element if `variant="[contained | fab]"` and `color="secondary"`. */
      containedSecondary: {
        '&:hover,&:focus': {
          backgroundColor: '#50eecf',
        },
      },
      /* Styles applied to the root element for backwards compatibility with legacy variant naming. */
      raised: {}, // legacy
      /* Styles applied to the root element for backwards compatibility with legacy variant naming. */
      raisedPrimary: {}, // legacy
      /* Styles applied to the root element for backwards compatibility with legacy variant naming. */
      raisedSecondary: {}, // legacy
      /* Styles applied to the root element if `variant="[fab | extendedFab]"`. */
      fab: {
        // borderRadius: '50%',
        // padding: 0,
        // minWidth: 0,
        // width: 56,
        // height: 56,
        // boxShadow: theme.shadows[6],
        // '&:active': {
        //   boxShadow: theme.shadows[12],
        // },
      },
      /* Styles applied to the root element if `variant="extendedFab"`. */
      extendedFab: {
        // borderRadius: 48 / 2,
        // padding: '0 16px',
        // width: 'auto',
        // minWidth: 48,
        // height: 48,
      },
      /* Styles applied to the ButtonBase root element if the button is keyboard focused. */
      focusVisible: {},
      /* Styles applied to the root element if `disabled={true}`. */
      disabled: {},
      /* Styles applied to the root element if `color="inherit"`. */
      colorInherit: {
        color: 'inherit',
      },
      /* Styles applied to the root element if `size="mini"` & `variant="[fab | extendedFab]"`. */
      mini: {
        width: 44,
        height: 44,
      },
      /* Styles applied to the root element if `size="small"`. */
      sizeSmall: {
        padding: '8px 16px',
        // minWidth: 64,
        // minHeight: 32,
        // fontSize: theme.typography.pxToRem(13),
      },
      /* Styles applied to the root element if `size="large"`. */
      sizeLarge: {
        padding: '15px 40px',
        // minWidth: 112,
        // minHeight: 40,
        // fontSize: theme.typography.pxToRem(15),
      },
      /* Styles applied to the root element if `fullWidth={true}`. */
      fullWidth: {
        width: '100%',
      },
    },

    // Paper
    MuiPaper: {
      elevation0: {
        boxShadow: 0,
        borderColor: grayColor,
        borderWidth: 1,
        borderStyle: 'solid',
      },
      rounded: {
        borderRadius: radius,
      },
    },

    MuiAppBar: {
      root: {
        backgroundColor: darkColor,
      },
      colorDefault: {
        backgroundColor: darkColor,
      },
    },

    MuiCardContent: {
      root: {
        padding: '20px !important',
      },
    },
  },
});
