import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';

import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';

import { StaticQuery, graphql } from 'gatsby';

import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import { theme } from '../theme/theme';

import Header from './Header';
import Footer from './Footer';
import CookieConsent from './CookieConsent';

// Override global body font. See https://github.com/mui-org/material-ui/issues/9988#issuecomment-426631645
const styles = () => ({
  '@global': {
    body: {
      fontFamily: theme.typography.fontFamily,
    },
    img: {
      maxWidth: '100%',
      height: 'auto',
    },
  },

  skipLink: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    overflow: 'hidden',
    position: 'absolute',
    '&:focus': {
      padding: '1.75rem',
      width: '100%',
      textAlign: 'center',
      position: 'fixed',
      top: '10px',
      left: '10px',
      borderRadius: 3,
      background: '#15E8BF',
      fontWeight: 600,
      textDecoration: 'none',
      color: '#37344C',
      zIndex: 9999,
      height: 'auto',
      clip: 'auto',
      outline: 'none',
      boxShadow: '0px 4px 4px rgba(55, 52, 76, 0.15)',
      [theme.breakpoints.up('md')]: {
        maxWidth: 300,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 20px)',
      },
    },
  },
});

const Layout = ({ children, title, titleSuffix, classes }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet
          title={[title, data.site.siteMetadata.title, titleSuffix]
            .filter(t => t)
            .join(' | ')}
          meta={[
            {
              name: 'description',
              content:
                'Whether you’re moving across the street or across the country, movetrac™ has everything you need to help keep everything about your move on track.',
            },
            {
              name: 'keywords',
              content:
                'moving, local moving, long-distance moving, moving checklist, moving services, moving tips, moving resources',
            },
            { name: 'og:title', content: 'movetrac™' },
            {
              name: 'og:description',
              content:
                'Whether you’re moving across the street or across the country, movetrac™ has everything you need to help keep everything about your move on track.',
            },
            {
              name: 'og:image',
              content: 'https://movetrac.com/img/movetrac-og.jpg',
            },
            { name: 'og:url', content: 'https://movetrac.com' },
            { name: 'twitter:card', content: 'summary_large_image' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <CookieConsent />
        <SkipNavLink className={classes.skipLink} />
        <Header siteTitle={data.site.siteMetadata.title} />

        <main className={classes.content} id="main">
          <SkipNavContent />
          {children}
        </main>
        <Footer />
      </MuiThemeProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Layout);
