import React from 'react';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faPinterest,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons';

import { orangePinkGradient, grayColor, blackGradient } from '../theme/theme';
import logo from '../images/logo--gray.svg';
import check from '../images/check.svg';
import Link from './Link';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.lightBlack,
    backgroundImage: blackGradient,
    position: 'relative',
    paddingTop: '10vw',
    paddingBottom: 60,
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  footerText: {
    color: '#fff',
  },
  gradientButtonOuter: {
    borderRadius: 3,
    display: 'block',
    overflow: 'hidden',
    marginTop: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  gradientButtonInner: {
    borderImage: orangePinkGradient,
    borderImageSlice: 1,
    borderWidth: 3,
    color: '#fff',
    fontSize: '1.5rem',
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    '& > span': {
      zIndex: 2,
    },
    '&:after': {
      backgroundImage: orangePinkGradient,
      borderBottom: 0,
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gradientButtonIcon: {
    marginLeft: 5,
    width: 24,
    height: 24,
  },
  footerContent: {
    marginTop: '10vw',
    marginBottom: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      marginTop: '5vw',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: -50,
    },
  },
  footerCheck: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  logo: {
    color: '#fff',
    width: 125,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing.unit / 2,
    paddingLeft: 0,
    marginLeft: -3,
    marginBottom: -3,
  },
  logoImg: {
    width: 125,
    height: 38,
  },
  footerLink: {
    color: grayColor,
    fontSize: '0.75rem',
    fontWeight: 400,
    marginTop: -4,
    marginLeft: theme.spacing.unit,
    textDecoration: 'underline',
  },
  footerSmall: {
    color: grayColor,
    fontSize: '0.75rem',
    marginTop: 5,
  },
  socialButtons: {
    marginBottom: theme.spacing.unit * 2,
    '@media (max-width: 767px) and (min-width: 600px)': {
      marginBottom: 6,
    },
    '@media (min-width: 768px)': {
      marginBottom: 0,
    },
  },
  socialIcon: {
    color: 'inherit',
  },
  socialButton: {
    backgroundColor: '#494760',
    borderRadius: 15,
    color: grayColor,
    display: 'inline-block',
    fontSize: '1rem',
    width: 30,
    height: 30,
    lineHeight: '30px',
    marginLeft: theme.spacing.unit,
    textAlign: 'center',
    transition: 'all 0.3s ease',

    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
      transform: 'scale(1.25)',
      outline: 'none',
    },
  },
  footerLeft: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  footerRight: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  copyright: {
    '@media (max-width: 767px) and (min-width: 600px)': {
      display: 'block',
    },
    '@media (max-width: 499px)': {
      display: 'block',
    },
  },
  facebookIcon: {
    width: 9,
    height: 16,
  },
  twitterIcon: {
    width: 16,
    height: 16,
  },
  pinterestIcon: {
    width: 16,
    height: 16,
  },
  instagramIcon: {
    width: 14,
    height: 16,
  },
});

const Year = new Date().getFullYear();

const Footer = ({ classes }) => (
  <footer className={classes.root}>
    <div className={classes.container}>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.footerContent}
      >
        <Grid item md={6} xs={12}>
          <Typography
            component="h2"
            variant="h1"
            gutterBottom
            className={classes.footerText}
          >
            Let's Get Moving!
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.footerText}
          >
            Interested in checking out what movetrac&reg; has to offer?
            Movetrac&reg; is free for everyone, so give us a try! We’re excited
            to help you with your next move!
          </Typography>
          <span className={classes.gradientButtonOuter}>
            <Button
              variant="outlined"
              href="https://app.movetrac.com/"
              className={classes.gradientButtonInner}
            >
              Try it Free!
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                className={classes.gradientButtonIcon}
              />
            </Button>
          </span>
        </Grid>

        <Grid item md={5} xs={12} className={classes.footerCheck}>
          <img src={check} alt="" />
        </Grid>
      </Grid>

      <Grid container spacing={24} justify="space-between">
        <Grid item sm={4} xs={12} className={classes.footerLeft}>
          <Button component={Link} to="/" className={classes.logo}>
            <img src={logo} className={classes.logoImg} alt="movetrac" />
          </Button>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.footerSmall}
          >
            Powered by TWO MEN AND A TRUCK&reg;
          </Typography>
        </Grid>

        <Grid item sm={8} xs={12} className={classes.footerRight}>
          <div className={classes.socialButtons}>
            <a
              aria-label="Facebook"
              href="https://www.facebook.com/TWOMENANDATRUCKINTERNATIONAL"
              className={classes.socialButton}
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                className={classNames(classes.socialIcon, classes.facebookIcon)}
              />
            </a>
            <a
              aria-label="Twitter"
              href="https://twitter.com/TwoMenAndATruck"
              className={classes.socialButton}
            >
              <FontAwesomeIcon
                icon={faTwitter}
                className={classNames(classes.socialIcon, classes.twitterIcon)}
              />
            </a>
            <a
              aria-label="Pinterest"
              href="https://www.pinterest.com/twomenandatruck/"
              className={classes.socialButton}
            >
              <FontAwesomeIcon
                icon={faPinterest}
                className={classNames(
                  classes.socialIcon,
                  classes.pinterestIcon,
                )}
              />
            </a>
            <a
              aria-label="Instagram"
              href="https://www.instagram.com/twomenandatruck/"
              className={classes.socialButton}
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className={classNames(
                  classes.socialIcon,
                  classes.instagramIcon,
                )}
              />
            </a>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.footerSmall}
            >
              <span className={classes.copyright}>
                © {Year} TWO MEN AND A TRUCK&reg;
              </span>
              <Button
                component={Link}
                to="/terms/"
                className={classes.footerLink}
              >
                Terms of Use
              </Button>
              <a
                href="https://www.servicemaster.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerLink}
              >
                Privacy Policy
              </a>
              <a
                href="https://www.servicemaster.com/accessibility-statement/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerLink}
              >
                Accessibility Statement
              </a>
              <a
                href="/cookies/"
                rel="noopener noreferrer"
                className={classes.footerLink}
              >
                Do Not Sell/Share My Info
              </a>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  </footer>
);

export default withStyles(styles)(Footer);
