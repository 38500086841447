import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleRight,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import { faSort } from '@fortawesome/free-solid-svg-icons';

import ResourceCategories from '../constants/ResourceCategories';
import { theme } from '../theme/theme';

const styles = () => ({
  root: {
    marginBottom: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit * 4,
  },
  form: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      height: 67,
    },
  },
  input: {
    margin: 0,
  },
  search: {
    width: '100%',
    paddingLeft: theme.spacing.unit * 4.5,
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  searchLabel: {
    top: 3,
  },
  searchInputContainer: {
    paddingTop: 14,
    '& > label + div': {
      marginTop: '6px !important',
    },
  },
  searchInput: {
    paddingBottom: 16,
    paddingLeft: theme.spacing.unit * 4.5,
    marginLeft: -(theme.spacing.unit * 4.5),
    '&:before': {
      display: 'none',
    },
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    top: 26,
    left: theme.spacing.unit * 2,
    width: 16,
    height: 16,
  },
  select: {
    width: '100%',
    position: 'relative',
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderTopWidth: 1,
    '& > label + div': {
      marginTop: '0 !important',
    },
    [theme.breakpoints.up('sm')]: {
      borderTopWidth: 0,
      borderLeftWidth: 1,
      width: '30%',
    },
  },
  selectLabel: {
    height: 0,
    overflow: 'hidden',
    opacity: 0,
    visibility: 'hidden',
  },
  selectInput: {
    cursor: 'pointer',
    paddingTop: theme.spacing.unit * 2.25,
    paddingBottom: theme.spacing.unit * 2.25,
    paddingLeft: theme.spacing.unit * 2,
    '&:after': {
      background: theme.palette.text.primary,
      content: '""',
      position: 'absolute',
      bottom: -1,
      left: 0,
      right: 0,
      width: '100%',
      height: '2px',
      margin: 'auto',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
    '&:hover, &:focus': {
      outline: 'none',
      '&:after': {
        transform: 'scaleX(1)',
      },
    },
  },
  selectIcon: {
    position: 'absolute',
    top: theme.spacing.unit * 2.25,
    right: theme.spacing.unit * 2,
    bottom: 0,
    margin: 0,
    display: 'block',
    pointerEvents: 'none',
    width: 11,
    height: 18,
  },
  selectMenu: {
    background: 'black !important',
  },
  submit: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '21%',
    },
  },
  submitButton: {
    borderRadius: 0,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  submitIcon: {
    marginLeft: theme.spacing.unit / 2,
    width: 18,
    height: 18,
  },
});

const SearchInput = ({
  onSubmit,
  onChange,
  searchQuery,
  category,
  classes,
}) => {
  const handleChange = e => {
    onChange(e.target.name, e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Card elevation={0} className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <FormControl
          className={classNames(classes.inputContainer, classes.search)}
        >
          <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
          <TextField
            label="Search"
            margin="dense"
            value={searchQuery}
            onChange={handleChange}
            className={classNames(classes.input, classes.searchInputContainer)}
            inputProps={{
              name: 'searchQuery',
              id: 'search',
            }}
            InputProps={{
              className: classes.searchInput,
            }}
            InputLabelProps={{
              className: classes.searchLabel,
            }}
          />
        </FormControl>
        <FormControl variant="outlined" className={classes.select}>
          <InputLabel htmlFor="category" className={classes.selectLabel}>
            Category
          </InputLabel>
          <Select
            variant="outlined"
            margin="dense"
            disableUnderline
            onChange={handleChange}
            onBlur={handleChange}
            value={category}
            fullWidth
            IconComponent={() => (
              <FontAwesomeIcon icon={faSort} className={classes.selectIcon} />
            )}
            SelectDisplayProps={{
              className: classNames(
                classes.inputContainer,
                classes.selectInput,
              ),
            }}
            inputProps={{
              name: 'category',
              id: 'category',
            }}
          >
            <MenuItem value="all">All topics</MenuItem>
            {ResourceCategories.map(catg => (
              <MenuItem key={catg.key} value={catg.key}>
                {catg.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="filled" className={classes.submit}>
          <Button
            type="submit"
            className={classes.submitButton}
            variant="contained"
          >
            Go{' '}
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              className={classes.submitIcon}
            />
          </Button>
        </FormControl>
      </form>
    </Card>
  );
};

SearchInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  category: PropTypes.string,
};

export default withStyles(styles)(SearchInput);
