import { theme } from '../theme';

export default {
  container: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 700,
    },
    '@media (min-width: 800px)': {
      maxWidth: 760,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 1000,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1200,
    },
  },

  containerSmall: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 600,
    },
    '@media (min-width: 800px)': {
      maxWidth: 750,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 900,
    },
  },
};
