import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import Img from 'gatsby-image';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import CardContent from '@material-ui/core/CardContent';

import container from '../theme/styles/container';
import section from '../theme/styles/section';
import resource from '../theme/styles/resource';
import Link from './Link';
import ResourceCategories from '../constants/ResourceCategories';

const styles = theme => ({
  ...container,
  ...section,
  ...resource,
  resourceCategory: {
    borderColor: '#fff',
    right: theme.spacing.unit * 3,
  },
  card: {
    background: '#fff !important',
    boxShadow: 'none',
    display: 'block',
    position: 'relative',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '4px',
      margin: 'auto',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
  },
  cardLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover > *, &:focus > *': {
      boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
      '&:after': {
        transform: 'scaleX(1)',
      },
    },
  },
  cardText: {
    lineHeight: '1.4',
  },
  cardImage: {
    height: 200,
    overflow: 'hidden',
    position: 'relative',
    marginBottom: -theme.spacing.unit,
  },
});

const ResourceGrid = ({ classes, resources, qs, articlesPerPage }) => {
  const resourceCategory = category =>
    ResourceCategories.find(x => x.key === category) || ResourceCategories[0];
  const currentPage = parseInt(qs.page, 10) || 1;
  const pageResources = resources.slice(
    (currentPage - 1) * articlesPerPage,
    currentPage * articlesPerPage,
  );
  return (
    <Grid container spacing={24}>
      {pageResources.map(({ node }) => (
        <Grid item md={4} sm={6} xs={12} key={node.id}>
          <Link to={node.fields.slug} className={classes.cardLink}>
            <Card
              elevation={0}
              className={classNames(
                classes.card,
                classes[node.frontmatter.category],
              )}
            >
              <CardMedia className={classes.cardImage}>
                <Img
                  fluid={node.frontmatter.banner.childImageSharp.fluid}
                  className={classes.resourceImage}
                  title="Banner Image"
                  alt={node.frontmatter.banner.publicURL}
                />
                <div className={classes.resourceImageMaskContainer}>
                  <img
                    src={withPrefix('/img/img-mask.svg')}
                    alt=""
                    className={classes.resourceImageMask}
                  />
                  <div
                    className={classNames(
                      classes.category,
                      classes.resourceCategory,
                      classes[node.frontmatter.category],
                    )}
                  >
                    <img
                      src={withPrefix(
                        resourceCategory(node.frontmatter.category).url,
                      )}
                      alt={node.frontmatter.category}
                      className={classes.resourceCategoryIcon}
                    />
                  </div>
                </div>
              </CardMedia>
              <CardContent>
                <Typography component="h3" variant="h3">
                  {node.frontmatter.title}
                </Typography>
                <Typography component="p" className={classes.cardText}>
                  {node.excerpt}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

ResourceGrid.propTypes = {
  resources: PropTypes.array,
  articlesPerPage: PropTypes.number.isRequired,
  qs: PropTypes.object,
};

export default withStyles(styles)(ResourceGrid);
