/* eslint-disable no-unused-expressions */
import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import { grayColor, secondaryColor, blackColor } from '../theme/theme';
import CookieContext from '../context/CookieContext';

const styles = theme => ({
  cookieBanner: {
    backgroundColor: theme.palette.common.lightBlack,
    position: 'fixed',
    bottom: -120,
    left: 0,
    right: 0,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    textAlign: 'center',
    zIndex: -1,
    opacity: 0,
    transition: 'all 0.3s ease',

    '.show-prompt &': {
      opacity: 1,
      zIndex: 20,
      bottom: 0,
    },
  },
  revokeButton: {
    position: 'fixed',
    bottom: -21,
    left: 0,
    zIndex: 10,
    fontSize: '0.85em',
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    transition: 'all 0.3s ease',

    '&:hover,&:focus': {
      bottom: 0,
    },
  },
  consentLink: {
    color: '#fff',
    textDecoration: 'underline',
  },
  consentText: {
    color: '#fff',
    marginTop: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit / 2,

    '@media (min-width: 768px)': {
      display: 'inline-block',
    },
  },
  consentButtons: {
    marginTop: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit / 2,

    '@media (min-width: 768px)': {
      display: 'inline-block',
    },
  },
  consentButton: {
    fontSize: '0.85em',
    marginLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    color: blackColor,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',

    '&:after': {
      borderColor: '#fff',
    },

    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      borderColor: '#fff',
      color: '#fff',
    },
  },
  allowButton: {
    backgroundColor: secondaryColor,
  },
  denyButton: {
    backgroundColor: grayColor,
  },
});

const CookieConsent = ({ classes }) => (
  <CookieContext.Consumer>
    {cookieContext => (
      <div
        className={classNames(
          classes.root,
          cookieContext.cookieConsentOpen ? 'show-prompt' : '',
        )}
      >
        <Button
          className={classes.revokeButton}
          variant="contained"
          tabIndex={cookieContext.cookieConsentOpen ? '-1' : '0'}
          onClick={() => cookieContext.toggleCookieConsentOpen(true)}
        >
          Cookie Settings
        </Button>
        <div className={classes.cookieBanner}>
          <div className={classes.consentText}>
            We use cookies to ensure you get the best experience on our website.
            Learn more about our{' '}
            <Link
              href="https://www.servicemaster.com/privacy-policy/"
              target="_blank"
              className={classes.consentLink}
            >
              privacy policy
            </Link>{' '}
            and{' '}
            <Link
              href="https://movetrac.com/cookies"
              target="_blank"
              className={classes.consentLink}
            >
              cookie use
            </Link>
            .
          </div>
          <div className={classes.consentButtons}>
            <Button
              className={classNames(classes.consentButton, classes.allowButton)}
              variant="contained"
              tabIndex={cookieContext.cookieConsentOpen ? '0' : '-1'}
              onClick={() => cookieContext.toggleCookieConsent(true)}
            >
              Allow
            </Button>
            <Button
              className={classNames(classes.consentButton, classes.denyButton)}
              variant="contained"
              tabIndex={cookieContext.cookieConsentOpen ? '0' : '-1'}
              onClick={() => cookieContext.toggleCookieConsent(false)}
            >
              Deny
            </Button>
          </div>
        </div>
      </div>
    )}
  </CookieContext.Consumer>
);

CookieConsent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CookieConsent);
