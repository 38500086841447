export default [
  {
    url: '/img/categories/college.svg',
    key: 'college',
    label: 'College',
  },
  {
    url: '/img/categories/moving.svg',
    key: 'moving',
    label: 'Moving',
  },
  {
    url: '/img/categories/packing.svg',
    key: 'packing',
    label: 'Packing',
  },
  {
    url: '/img/categories/tips-tricks.svg',
    key: 'tips',
    label: 'Tips and Tricks',
  },
];
