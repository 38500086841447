import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import queryString from 'query-string';

const CpLink = props => {
  const { location } = queryString.parse(props.locationprops.location.search);
  const [, root, qs] = /^(.*?)(\?.*)?$/.exec(props.to);
  const newQsString = queryString.stringify({
    ...queryString.parse(qs),
    location,
  });
  const toLink = newQsString ? `${root}?${newQsString}` : root;

  return <Link {...props} to={toLink} />;
};

CpLink.propTypes = {
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object,
  innerRef: PropTypes.func,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
  replace: PropTypes.bool,
  locationprops: PropTypes.object,
};

const LinkLocationWrapper = props => (
  <Location>
    {locationProps => <CpLink {...props} locationprops={locationProps} />}
  </Location>
);

export default LinkLocationWrapper;
