import { theme } from '../theme';

export default {
  section: {
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,

    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing.unit * 8,
      paddingBottom: theme.spacing.unit * 8,
    },
  },
};
