import { theme } from '../theme';
import { primaryColor, secondaryColor, tertiaryColor, darkColor } from '../theme';

export default {
  category: {
    borderWidth: 4,
    borderStyle: 'solid',
    borderRadius: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 50,
    position: 'absolute',
    top: -17,
    right: theme.spacing.unit * 4,
    zIndex: 3,
    [theme.breakpoints.up('sm')]: {
      top: -19,
    },
    '@media (min-width: 800px)': {
      top: -15,
    },
    [theme.breakpoints.up('md')]: {
      top: -19,
    },
    [theme.breakpoints.up('lg')]: {
      top: -15,
    },
  },
  moving: {
    backgroundColor: secondaryColor,
    '&:after': {
      backgroundColor: secondaryColor,
    }
  },
  packing: {
    backgroundColor: tertiaryColor,
    '&:after': {
      backgroundColor: tertiaryColor,
    }
  },
  college: {
    backgroundColor: darkColor,
    '&:after': {
      backgroundColor: darkColor,
    }
  },
  tips: {
    backgroundColor: primaryColor,
    '&:after': {
      backgroundColor: primaryColor,
    }
  },
  resourceImageMaskContainer: {
    position: 'absolute',
    left: '-2%',
    right: '-2%',
    bottom: '-2%',
    zIndex: 2,
  },
  resourceImageMask: {
    width: '100%',
    marginBottom: -1,
  },
  resourceImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  resourceCategoryIcon: {
    maxWidth: 25,
    maxHeight: 25,
    width: 'auto',
    height: 'auto',
  },
};
