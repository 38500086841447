import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { greenGradient } from '../theme/theme';
import logo from '../images/logo.svg';

import Link from './Link';

const styles = theme => ({
  root: {
    border: 'none',
    position: 'relative',
    zIndex: 100,
    '.home &': {
      backgroundColor: 'transparent !important',
    },
  },
  toolbar: {
    maxWidth: 1200,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  logo: {
    color: '#fff',
    width: 150,
    paddingBottom: theme.spacing.unit * 2,

    '@media (min-width: 768px)': {
      paddingBottom: theme.spacing.unit,
    },
  },
  logoImg: {
    width: 150,
    height: 39,
  },
  headerLink: {
    color: theme.palette.common.black,
    fontSize: '1.5rem',
    margin: theme.spacing.unit,
    '@media (min-width: 768px)': {
      color: '#fff',
      fontSize: '1.125rem',
    },
  },
  headerButton: {
    borderColor: theme.palette.common.black,
    color: theme.palette.common.black,
    fontSize: '1.5rem',
    margin: theme.spacing.unit,
    paddingTop: theme.spacing.unit / 1.25,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,

    '&:after': {
      borderColor: theme.palette.secondary.main,
    },

    '@media (min-width: 768px)': {
      borderColor: '#fff',
      color: '#fff',
      fontSize: '1.125rem',
      paddingTop: theme.spacing.unit / 2,
      paddingBottom: theme.spacing.unit / 2,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,

      '&:hover, &:focus': {
        background: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
      },
    },
  },
  logoContainer: {
    position: 'relative',
    zIndex: 5,
  },
  mobileButtons: {
    marginTop: 20,
    position: 'relative',
    zIndex: 5,
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  mobileButton: {
    color: '#fff',
    display: 'block',
    fontSize: '1.5rem',
    minWidth: 44,
    padding: '10px 0 21px',
    marginBottom: -12,
  },
  bar: {
    transition: 'all 0.3s ease',
    backgroundColor: '#fff',
    borderRadius: 1,
    display: 'block',
    width: 24,
    height: 3,
    margin: '0 auto',
    position: 'relative',
    '& + &': {
      marginTop: 5,
    },
  },
  barMiddle: {
    '.show-nav &': {
      opacity: 0,
    },
  },
  barFirst: {
    '.show-nav &': {
      transform: 'rotate(45deg)',
      top: 8,
    },
  },
  barLast: {
    '.show-nav &': {
      transform: 'rotate(-45deg)',
      top: -8,
    },
  },
  navigation: {
    transition: 'width 0.5s ease-in-out',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 0,
    height: '100vh',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      padding: '71px 0 0 0 !important',
      '.show-nav &': {
        width: '100%',
      },
    },
    '@media (min-width: 768px)': {
      display: 'block',
      position: 'static',
      background: 'transparent',
      width: 'auto',
      height: 'auto',
    },
  },
  menu: {
    background: greenGradient,
    display: 'flex',
    height: '100%',
    width: '100%',
    minWidth: '200px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (min-width: 768px)': {
      background: 'transparent',
      display: 'inline',
      height: 'auto',
      width: 'auto',
      float: 'left',
    },
  },
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggleHamburger = this.toggleHamburger.bind(this);
    this.state = { showHamburger: false };
  }

  toggleHamburger(e) {
    switch (e.type) {
      case 'focus':
        this.setState({
          showHamburger: true,
        });
        break;
      case 'blur':
        this.setState({
          showHamburger: false,
        });
        break;
      case 'click':
        this.setState({
          showHamburger: !this.state.showHamburger,
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { classes, siteTitle } = this.props;
    const toggleClass = this.state.showHamburger ? 'show-nav' : '';

    return (
      <AppBar
        elevation={0}
        position="static"
        color="default"
        className={classNames(classes.root, toggleClass)}
      >
        <Toolbar
          varient="dense"
          disableGutters={true}
          className={classes.toolbar}
        >
          <Grid container spacing={24} justify="space-between">
            <Grid item className={classes.logoContainer}>
              <Button component={Link} to="/" className={classes.logo}>
                <img src={logo} alt={siteTitle} className={classes.logoImg} />
              </Button>
            </Grid>

            <Grid item className={classes.mobileButtons}>
              <Button
                className={classes.mobileButton}
                onClick={this.toggleHamburger}
              >
                <span className={classNames(classes.bar, classes.barFirst)} />
                <span className={classNames(classes.bar, classes.barMiddle)} />
                <span className={classNames(classes.bar, classes.barLast)} />
              </Button>
            </Grid>

            <Grid item className={classes.navigation}>
              <div className={classes.menu}>
                <Button
                  component={Link}
                  to="/resources/"
                  className={classes.headerLink}
                >
                  Resources
                </Button>
                <Button
                  component={Link}
                  to="/faqs/"
                  className={classes.headerLink}
                >
                  FAQs
                </Button>
                <Button
                  component={Link}
                  to="/contact/"
                  className={classes.headerLink}
                >
                  Contact
                </Button>
                <Button
                  variant="outlined"
                  className={classes.headerButton}
                  href="https://app.movetrac.com/"
                >
                  Sign In
                </Button>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
};

export default withStyles(styles)(Header);
